<template>
    <!-- form modal -->

    <div v-if="isAccountModal" tabindex="0" @keydown.esc="closeModal" class="w-100 center pt0-l pt7" id="entity-form"
        :class="isAccountModal && 'jquery-modal blocker current'" @click="handleOnClickOutsideModal">
        <div class="w-50-l w-90 pv2 center box-border mv3" style="background: white" ref="createEntityRef">
            <div class="f4 b pa3">
                {{ state?.type.toUpperCase() }} <span>( {{ state.date }} )</span>
            </div>
            <div class="ph3 b flex items-center">
                <div>
                    <img src="imgs/profile-cj.svg" width="60px" alt="" />
                </div>
                <div class="pl3">
                    <div class="lh-title">Customer Name: {{ state.entityName }}</div>
                    <!-- <div class="pt2">Edit</div> -->
                </div>
            </div>
            <div class="flex flex-wrap justify-between pa3 w-100">
                <div class="scrollmenu w-60-l w-100">
                    <table v-if="state.products" class="w-100 tl mt3" cellspacing="0">
                        <thead>
                            <tr>
                                <th class="pb3 pr3 bb b--black-20 tl">Name</th>
                                <th class="pb3 pr3 bb b--black-20 tl">Price</th>
                                <th class="pb3 pr3 bb b--black-20 tc">QTY</th>
                                <th class="pb3 bb b--black-20 tr pr3">Total</th>
                            </tr>
                        </thead>
                        <tr v-for="(product, index) of state.products" v-bind:key="index" class="font-w1">
                            <td class="pv3 pr3 bb b--black-20 tl text-truncate tooltip edittooltipselect">
                                {{ product?.name?.length > 30 ? `${product.name?.slice(0, 30)}...` : product?.name }}
                                <!-- <span v-if="product?.name?.length > 20" class="tooltiptext" style="padding: 10px;">{{
                                    product?.name
                                }}</span> -->
                            </td>
                            <td class="pv3 pr3 bb b--black-20 tl">{{ product.price }}</td>
                            <td class="pv3 pr3 bb b--black-20 tc">{{ product.quantity }}</td>
                            <td class="pv3 pr3 bb b--black-20 tr">{{ product.quantity * product.price }}</td>
                        </tr>
                    </table>

                    <table v-else class="w-100 tl mt3" cellspacing="0">
                        <tr class="font-w1">
                            <td class="pv3 pr3 bb b--black-20 tl text-truncate">SubType: {{ state.subtype }}</td>
                            <td class="pv3 pr3 bb b--black-20 tl">Description: {{ state.description }}</td>
                        </tr>
                    </table>

                    <div class="pb3">
                        <div class="pt0 ph3">
                            <div class=" w-100" style="font-size: 14px;">
                                <div class="flex justify-between  b--black-20 pt3 pb2">
                                    <div class="b">Subtotal</div>
                                    <div v-if="incomingData?.credit">
                                        {{ formatAmount(state.creditAmount, $store.state.Settings?.currency) }}
                                    </div>
                                    <div v-else class=" b">{{ formatAmount(state.amount,
                                        $store.state.Settings?.currency) }}</div>
                                </div>
                                <div v-for="option of state.options" v-bind:key="option">
                                    <!-- 
                                    amount: 20
                                    label: "shipping"
                                    type: "fixed"
                                 -->
                                    <div class="flex justify-between  b--black-20 pv2">
                                        <div class="" style="text-transform: capitalize; font-weight:600">
                                            {{ option.label }}
                                            <!-- {{ option.label === 'discount' ? `(${option.amount + '%'})` : '' }} -->
                                        </div>
                                        <div class="" style="font-weight:600">
                                            <!-- {{ option }} -->
                                            {{
                                            option.type === 'fixed'
                                            ? formatAmount(option.amount, $store.state.Settings?.currency)
                                            : option.label === 'discount'
                                            ? `(${option.amount + '%'})`
                                            : option.amount + '%'
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="flex justify-between  b--black-20 pv2" > 
                                <div class="">Discount</div>
                                <div class="">$35,000</div>
                            </div>
                            <div class="flex justify-between  b--black-20 pv2" > 
                                <div class="">Shipping</div>
                                <div class="">$35,000</div>
                            </div> -->
                            </div>
                            <div></div>
                        </div>
                    </div>
                    <div class="flex justify-between bt bb b b--black-20 pv3">
                        <div>Total</div>
                        <div v-if="incomingData?.credit" class="pr2">
                            {{ formatAmount(overallCost, $store.state.Settings?.currency) }}
                        </div>
                        <div v-else class="pr2">{{ formatAmount(state.subtotal, $store.state.Settings?.currency) }}
                        </div>
                    </div>
                </div>

                <div class="w-40-l w-100">
                    <div id="amountToPay" class="pb3">
                        <div class="b pb3 pt0-l pt3">Amount to pay</div>
                        <div  v-tippy="{ content: ' Input the amount you received from the customer via a specific payment method e.g for a product that cost N500, N200 was given as cash and N300 paid into your bank', placement: 'top', interactive: true }"><input type="number" style="padding:10px; box-sizing: border-box; overflow: hidden;"
                            class="acc-amount-2" ref="inputAmountRef" v-model="amount"
                            @change="value => onAmountChange(value, 'inputAmount')"
                            :placeholder="state.subtotal - state.glAmountSum" /></div>
                        <div class="pt2" style="font-size:10px ; width: 150px; margin: auto">Choose mode of payment or edit for multiple payment</div>
                    </div>
                    <span>
                        <button ref="accountTypeRef" class="btn3 w-30-l ml1 mb1"
                            @click="value => onAccountSelect(value)" :value="'Cash'">
                            Cash
                        </button>
                    </span>
                    <span v-for="(account, index) in bankAccounts" v-bind:key="index">
                        <button ref="accountTypeRef" class="btn3 w-30-l ml1 mb1"
                            @click="value => onAccountSelect(value)" :value="account?.aliasName || account?.bankName">
                            {{ account?.aliasName || account?.bankName }}
                        </button>
                    </span>
                </div>
            </div>

            <div class="ph3 w-100">
                <div class="w-60-l w-100 ph3" style="font-size: 14px;">
                    <ol v-for="(account, index) of state.glAccounts" v-bind:key="index">
                        <div class="flex justify-between  b--black-20 pv1">
                            <img ref="removeAccountRef" class="black-60 hover-black-50"
                                @click.prevent="removeFromGlAccounts(account.glAccount, account.glAmount, index)"
                                src="https://img.icons8.com/dotty/22/000000/delete-sign.png" alt="" />

                            <div class="">{{ account.glAccount }}</div>
                            <div class="">{{ formatAmount(account.glAmount, $store.state.Settings?.currency) }}</div>
                        </div>
                    </ol>
                    <ol v-if="incomingData?.credit">
                        <div class="flex justify-between  b--black-20 pv3">
                            <div>Credit</div>
                            <div class="pr2">
                                {{ formatAmount(overallCost - incomingData.initial_deposit,
                                $store.state.Settings?.currency) }}
                            </div>
                        </div>
                    </ol>
                    <ol v-if="incomingData?.useAdvancePayment">
                        <div class="flex justify-between  b--black-20 pv3">
                            <div>Advance payment</div>
                            <div class="pr2">
                                {{ formatAmount(incomingData.entityPaidAdvanceAmount, $store.state.Settings?.currency)
                                }}
                            </div>
                        </div>
                    </ol>
                </div>
                <div class="w-60-l w-100 pt3">
                    <div class="flex justify-between bt bb b b--black-20 pv3">
                        <div><strong>Remaining</strong></div>
                        <div class="pr2">
                            {{ formatAmount(state.subtotal - state.glAmountSum, $store.state.Settings?.currency) }}
                        </div>
                    </div>
                </div>
            </div>

            <!-- Add Transaction -->
            <div class="pa3 flex justify-between">
                <div></div>
                <div>
                    <button :disabled="state.disableSubmitButton" @click.prevent="submitTransaction">
                        {{ state.disableSubmitButton ? 'Please wait...' : 'Add Transaction' }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { reactive, computed, ref, watch, onMounted, watchEffect } from 'vue'
    import { useStore } from 'vuex'
    import Swal from 'sweetalert2'
    import { formatAmount } from '@/utils/lib'
    import { useRoute, useRouter } from 'vue-router'

    export default {
        name: 'SelectAccount',
        props: {
            firstName: String,
            overallCost: Number,
            closeModal: Function
        },

        setup() {
            const createEntityRef = ref(null)
            const formRef = ref(null)
            const firstNameRef = ref(null)
            const accountTypeRef = ref(null)
            const removeAccountRef = ref(null)
            const inputAmountRef = ref(null)
            const store = useStore()
            const router = useRouter()
            const amount = ref(0)
            const keepOriginalAmount = ref(0)

            // const incomingName = computed(() => store.state.Entity?.incomingEntityName)
            // const isLoading = computed(() => store.state.Transaction?.isLoading)

            const incomingData = computed(() => store.state.Transaction?.formInformation)
            const bankAccounts = computed(() => store.state?.Settings?.bankAccounts)

            const initialState = {
                disableSubmitButton: false,
                products: [],
                amount: 0,
                creditAmount: 0,
                discountedAmount: 0,
                type: '',
                date: '',
                accountTypes: ['Cash', 'Bank'],
                accountType: '',
                glAccounts: [],
                inputAmount: 0,
                glAmountSum: 0,
                entityName: '',
                data: {},
                options: [],
                subtotal: 0,

                subtype: '',
                description: '',
                isRemoveButtonClicked: false
            }

            let state = reactive(initialState)

            const isAccountModal = computed(() => store?.state.Transaction.showAccountModal)
            watch(
                () => isAccountModal?.value,
                () => {
                    document.body.style.overflow = isAccountModal.value ? 'hidden' : 'auto'
                    // console.log('isAccount', store?.state.Transaction.showAccountModal)
                }
            )

            watchEffect(() => {
                if (store?.state.Transaction.showAccountModal === false) {
                    // state.glAccounts.length = 0
                    state.glAccounts.length = 0
                    state.glAmountSum = 0
                    // console.log('effecttt', state.glAccounts)
                }

                if (store?.state.Transaction.showAccountModal) {
                    amount.value = state.subtotal - state.glAmountSum
                }
                keepOriginalAmount.value = state.subtotal - state.glAmountSum
                // console.log(amount.value, 'montpelier')
            })

            watch(
                () => incomingData?.value,
                (prev, curr) => {
                    state.amount = prev.credit ? prev.initial_deposit : prev.amount
                    state.creditAmount = prev.amount
                    state.products = prev.items || prev.products
                    state.type = prev.type
                    state.date = prev.date
                    state.entityName = prev.entityName
                    state.options = prev.options
                    state.data = prev

                    state.discountedAmount =
                        state.amount +
                        prev?.options?.reduce((acc, item) => {
                            // if label is discount
                            if (item.label == 'discount') {
                                if (item.type === 'percent') {
                                    return acc - state.amount * (item.amount / 100)
                                } else {
                                    return acc - item.amount
                                }
                            }

                            return acc
                        }, 0)

                    const calculateExtras =
                        state.discountedAmount +
                        prev?.options?.reduce((acc, item) => {
                            // if label is discount
                            if (item.label !== 'discount') {
                                if (item.type === 'percent') {
                                    return acc + state.discountedAmount * (item.amount / 100)
                                } else {
                                    return acc + item.amount
                                }
                            }
                            return acc
                        }, 0)

                    // console.log(prev, 'data')

                    if (incomingData?.value?.credit) {
                        state.subtotal = state.amount
                    } else {
                        state.subtotal = !calculateExtras ? state.amount : calculateExtras
                    }

                    state.subtype = prev.subtype
                    state.description = prev.description
                    state.inputAmount = state.subtotal

                    // console.log('useAdvancePayment: ', incomingData?.value?.useAdvancePayment)
                    // console.log('entityPaidAdvanceAmount: ', incomingData?.value?.entityPaidAdvanceAmount)
                    // console.log('Subtotal: ', state.subtotal)
                    // console.log('incomingData: ', incomingData?.value)

                    if (incomingData?.value?.useAdvancePayment) {
                        state.inputAmount = state.subtotal - (incomingData?.value?.entityPaidAdvanceAmount || 0)
                        state.glAmountSum = incomingData?.value?.entityPaidAdvanceAmount || 0
                    }

                    // console.log(calculateExtras, 'all extras');
                }
            )

            const onAccountSelect = value => {
                // check if the amount is zero
                // console.log('test', state.subtotal - state.glAmountSum)
                // console.log('original account select', keepOriginalAmount.value - amount.value)
                keepOriginalAmount.value - amount.value

                state.inputAmount = amount.value
                if (parseFloat(state.inputAmount) === 0) {
                    Swal.fire({
                        title: 'Error',
                        text: 'Total amount paid into cash/bank cannot exceed the transaction amount. Please reconfirm and if zero (0), proceed to “Add transaction”',
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                    return
                }

                // check if the amount is greater than the amount to pay
                state.glAmountSum += parseFloat(state.inputAmount)
                if (state.glAmountSum > parseFloat(state.subtotal)) {
                    // console.log('na me ooo', state.glAmountSum)
                    Swal.fire({
                        title: 'Error',
                        text: `${state.glAmountSum} cannot be greater than the amount to ${state.subtotal}`,
                        icon: 'error'
                    })
                    // reset the amount
                    state.glAmountSum -= parseFloat(state.inputAmount)
                    return
                }

                state.accountType = value.target.value
                state.glAccounts.push({ glAccount: state.accountType, glAmount: state.inputAmount })
                // console.log('index', state.glAccounts)

                // state.glAccounts.push({ glAccount: state.accountType, glAmount: state.inputAmount })

                // Swal.fire(
                //     `${state.accountType}`,
                //     `${state.inputAmount}`,
                //     'success'
                //     )
                //     // initialize the amount
                state.inputAmount = 0
                inputAmountRef.value = 0
                amount.value = state.subtotal - state.glAmountSum
            }
            // onNameChange

            const onAmountChange = (value, stateName) => {
                // console.log('vale', value.target.value)
                state[stateName] = value.target.value
            }

            const closeAccountModal = () => {
                // store.dispatch('Inventory/resetProductIds')
                store.dispatch('Transaction/showAccountModal', false)
            }

            const submitTransaction = () => {
                const formData = { ...state.data, glAccounts: state.glAccounts }

                // check if the glAccounts is empty
                if (state.glAccounts.length === 0) {
                    if (incomingData?.value?.credit && incomingData?.value?.initial_deposit > 0) {
                        Swal.fire({
                            title: 'Error',
                            text: 'Please select at least one account',
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                        return
                    }
                }

                // check if the amount is not equal to the amount to pay
                if (state.glAmountSum !== parseFloat(state.subtotal) && state.subtype !== 'advance_payment') {
                    Swal.fire({
                        title: 'Error',
                        // text: `${state.glAmountSum} is not equal to the amount to pay ${state.subtotal}`,
                        text: `Please select the mode of payment e.g cash`,
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                    return
                }

                const { disableSubmitButton, data } = state

                // set loading to true
                store.dispatch('Transaction/setIsLoading', true)
                state.disableSubmitButton = true
                store.dispatch('Transaction/createNewTransaction', formData).then(resp => {
                    if (resp?.success) {
                        Object.assign(state, initialState)
                        state = reactive(initialState)
                        state.products = []
                        state.amount = 0
                        state.glAccounts = []
                        state.glAmountSum = 0
                        store.dispatch('Inventory/resetProductIds')
                        state.disableSubmitButton = false
                        closeAccountModal()
                        // route to the transaction page
                        router.push({ name: 'Transaction', params: { id: formData?.reference } })
                    } else {
                        store.dispatch('Inventory/resetProductIds')
                        closeAccountModal()
                        state.disableSubmitButton = false
                    }
                })
            }

            const handleOnClickOutsideModal = e => {
                if (createEntityRef?.value && !createEntityRef?.value.contains(e.target) && !state.isRemoveButtonClicked) {
                    closeAccountModal()
                } else {
                    state.isRemoveButtonClicked = false
                }
            }

            // remove from glAccounts
            const removeFromGlAccounts = (accountType, amount, index) => {
                state.isRemoveButtonClicked = true
                state.glAccounts.splice(index, 1)
                state.glAmountSum -= parseFloat(amount)
                state.inputAmount = amount
            }

            onMounted(() => {
                store.dispatch('Settings/getBankAccounts')
            })

            return {
                state,
                submitTransaction,
                isAccountModal,
                amount,
                // incomingName,
                onAccountSelect,
                firstNameRef,
                formRef,
                createEntityRef,
                handleOnClickOutsideModal,
                accountTypeRef,
                inputAmountRef,
                removeAccountRef,
                onAmountChange,
                formatAmount,
                removeFromGlAccounts,
                incomingData,
                bankAccounts,
                keepOriginalAmount
            }
        }
    }
</script>

<style scoped>
    .acc-body {
        width: 800px;
        min-height: 500px;
        margin: auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .acc-body-c1 {
        background-color: ghostwhite;
        padding: 15px;
        overflow: auto !important;
    }

    .acc-body-c2 {
        background-color: ghostwhite;
        padding: 10px;
    }

    .acc-body-c3 {
        background-color: white;
        max-width: 100%;
        min-height: 100%;
        /* padding: 10px; */
        overflow: auto !important;
    }

    .acc-btn {
        background-color: #132c8c;
        width: 100px;
        min-height: 60px;
        padding: 10px;
        margin: 5px;
        flex-wrap: wrap;
        color: #fff;
        box-shadow: none;
        border: none;
        border-radius: 3px;
        cursor: pointer;
    }

    .acc-btn:hover {
        background-color: #334280;
    }

    .acc-amount-iner {
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .acc-amount-1 {
        padding-right: 20px;
    }

    .acc-amount-2 {
        border: 1px solid gray;
        width: 50%;
        height: 40px;
        border-radius: 3px;
    }

    .acc-balance-c1 {
        display: flex;
        justify-content: space-between;
        padding-top: 10px;
        padding-bottom: 10px;
        /* border-bottom: 1px solid rgb(201, 201, 201); */
    }

    .acc-balance-sub {
        display: flex;
        justify-content: space-between;
        padding-top: 10px;
    }

    .acc-balance-c3 {
        display: flex;
        justify-content: space-between;
        margin-left: 26px;
        padding-top: 50px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgb(201, 201, 201);
    }

    .Sales-title {
        font-size: 20px;
        font-weight: 600;
    }

    .Sales-title1 {
        width: 20px;
        height: 15px;
        cursor: pointer;
    }

    .edittooltipselect {}
</style>