<!-- eslint-disable -->
<template>
    <!-- modal invite team -->
    <div :class="lowStocks.length && 'jquery-modal blocker current'" @click="onClickOutsideTransactionRef">
        <div class="w-90 mv3 center" :class="!lowStocks.length ? 'modal2' : 'modal-target2'">
            <form
                class="box-border relative"
                style="background: white"
                ref="transactionModalRef"
                @submit.prevent="handleProcessTransaction"
            >
                <router-link to="#" class="absolute top--1 right--2" @click.prevent="closeModal">
                    <img src="../../../assets/images/close-icon-2.png" alt="" class="w-40 h-40" />
                </router-link>
                <div class="tc pt3 bb b--black-10 ">
                    <img src="@/assets/images/Low-stock.svg" style="width:60px;" class="pt4" alt="" />
                    <h2 class="lh-title">Low stock alert!!!</h2>
                </div>
                <div class="pa3 box-border-bottom">
                    <div class="flex flex-column pb3 items-start">
                        <div class="font-w1 lh-copy">
                            This following items below are low in stock or does not exist in your store. Proceeding might impact
                            your inventory figures negatively.
                        </div>
                    </div>
                    <ul class="flex flex-column items-start pl3-l" v-for="lowStock of lowStocks" :key="lowStock">
                        <!--                        <label class="pb2 font-w1">Choose</label>-->
                        <li class="pv2 font-w1">{{ lowStock }}</li>
                    </ul>

                    <!-- <div class="flex flex-column pb3 items-start"> -->
                    <div class="font-w1 lh-copy tc pb3">
                        Do you want to proceed?
                    </div>
                    <!-- </div> -->
                </div>
                <div class="flex justify-end pa3">
                    <div class="db-l dn"></div>
                    <div>
                        <button class="btn2" @click.prevent="handleProcessTransactionModal(false)">Cancel</button>
                        <button class="ml2" :disabled="state.disabledButton" type="submit">
                            {{ state.disabledButton ? 'Processing, Please wait...' : 'Proceed' }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
/* eslint-enable */
import { reactive, ref } from 'vue'

export default {
    name: 'LowStockNotice',
    props: {
        closeModal: Function,
        processSubmit: Function,
        lowStocks: {
            type: Array,
            // eslint-disable-next-line vue/require-valid-default-prop
            default: []
        }
    },

    setup(props) {
        const initialState = () => ({
            disabledButton: false
        })

        const state = reactive(initialState())

        const transactionModalRef = ref(null)

        const handleProcessTransactionModal = () => {
            state.disabledButton = false
            // eslint-disable-next-line
            props.closeModal()
        }

        const onClickOutsideTransactionRef = (e) => {
            if (
                // eslint-disable-next-line
                transactionModalRef.value &&
                // eslint-disable-next-line
                !transactionModalRef.value.contains(e.target)
            ) {
                props.closeModal()
            }
        }

        const handleProcessTransaction = () => {
            state.disabledButton = true
            props.processSubmit()
            state.disabledButton = false
        }

        return {
            state,
            transactionModalRef,
            handleProcessTransaction,
            handleProcessTransactionModal,
            onClickOutsideTransactionRef
        }
    }
}
/* eslint-enable */
</script>

<style scoped></style>
<!-- eslint-disable -->
