<template>
    <!-- form modal -->
    <div
        tabindex="0"
        @keydown.esc="toggleProductVariantsModal({ status: false, index: null })"
        class="w-100 center modal pt0-l pt7"
        id="add-product-form"
        :class="showProductVariantsModal && 'modal-target'"
        style="z-index: 999999"
        @click="handleOnClickOutsideModal"
    >
        <div class="w-30-l w-90 pv2 center box-border" style="background: white" ref="productVariantsRef">
            <div class="flex justify-between box-border-bottom pa3">
                <div class="b f3">Choose Variant</div>
                <div>
                    <router-link to="#" @click.prevent="toggleProductVariantsModal({ status: false, index: null })">
                        <img :src="require('@/assets/images/Close-icon.svg')" alt=" " />
                    </router-link>
                </div>
            </div>
            <form class="entity-form" ref="formRef">
                <div class="flex justify-start items-center pa3">
                    <a href="#" v-for="variant in productVariants" 
                        :key="variant._id" 
                        class="flex flex-column justify-center items-center box-border pa2 w-30 mr2" 
                        style="height: 50px;"
                        @click="selectVariant(variant)"
                    >
                        <div class="b">{{ variant.name }}</div>
                        <div>{{ formatAmount(variant?.sellingPrice, $store.state.Settings?.currency) }}</div>
                    </a>              
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { formatAmount } from '@/utils/lib'

export default {
    name: 'ProductVariantsModal',
    
    setup(props, { emit }) {
        const productVariantsRef = ref(null)
        const store = useStore()
        const showProductVariantsModal = computed(() => store?.state.Inventory.showProductVariantsModal)
        const productVariants = computed(() => store?.state.Inventory.productVariants)
        const productIndex = computed(() => store?.state.Inventory.productIndex)

        const toggleProductVariantsModal = (payload) => {
            store.dispatch('Inventory/toggleProductVariantsModal', payload)
        }

        const selectVariant = (variant) => {
            emit('onVariantSelected', variant, productIndex.value)
            toggleProductVariantsModal({ status: false, index: null })
        }

        const handleOnClickOutsideModal = e => {
            if (productVariantsRef.value && !productVariantsRef.value.contains(e.target)) {
                toggleProductVariantsModal({ status: false, index: null })
            }
        }

        onMounted(() => store.dispatch('Inventory/getAllCategories'))

        return {
            productVariants,
            showProductVariantsModal,
            toggleProductVariantsModal,
            selectVariant,
            productVariantsRef,
            handleOnClickOutsideModal,
            formatAmount,
        }
    }
}
</script>

<style scoped></style>
