<template>
    <!-- form modal -->

    <div
        v-if="isCreateAssetCategory"
        tabindex="0"
        @keydown.esc="closeModal"
  
        class="w-100 center modal pt0-l pt7"
        id="add-product-form"
        :class="isCreateAssetCategory && 'modal-target'"
        style="z-index: 999999"
        @click="handleOnClickOutsideModal"
    >
        <div class="w-50-l w-90 pv2 center box-border" style="background: white" ref="createEntityRef">
            <div class="flex justify-between pa3">
                <div></div>
                <div>
                    <router-link to="#">
                        <img src="../assets/images/Close-icon.svg" alt="" @click.prevent="closeModal" />
                    </router-link>
                </div>
            </div>

            <!--      Previously div tag-->
            <form class="entity-form" @submit.prevent="submitCreateAsset" ref="formRef">
                <div class="box-border-bottom f3 pa3 b">Create New Asset Category</div>

                <div class="pa3">
                    <!-- full name -->
                    <div class="flex flex-wrap items-end justify-between">
                        
                        <div class="w-50-l w-100 pr2-l pb0-l pb3">
                            <div class="pv2 font-w1">
                                <label for="group">Group<span class="required">*</span></label>
                            </div>
                            <!--   <input type="text" placeholder="First name" id="group" name="group" v-model="state.group" />-->
                            <input
                                type="text"
                                ref="groupRef"
                                placeholder="Machinery and Equipment"
                                id="group"
                                name="group"
                                required
                                @change="(value) => onNameChange(value, 'group')"
                                :value="incomingName || ''"
                            />
                        </div>
                        <div class="w-50-l w-100 pl2-l">
                            <div class="pv2 font-w1">
                                <label for="category">Category</label>
                            </div>
                            <input
                                type="text"
                                id="category"
                                name="category"
                                placeholder="Books and Multimedia Materials"
                                @change="(value) => onNameChange(value, 'category')"
                            />
                        </div>
                    </div>
                    <!-- Company & number -->
                    <div class="flex flex-wrap justify-between pt2">
                        <div class="w-100 pr2-l" :class="state.showMore && 'w-50-l'">
                            <div class="pv2 font-w1"><label for="lifeSpan">Life Span</label></div>
                            <input
                                type="number"
                                id="lifeSpan"
                                name="lifeSpan"
                                @change="(value) => onNameChange(value, 'lifeSpan')"
                                placeholder="5"
                            />
                        </div>
                   </div>
                </div>
            </form>
                 <!-- center the button -->
                    <div class="w-100">
                        <button
                            class="btn btn-primary"
                            :class="state.showMore && 'w-50-l'"
                            :disabled="state.disableSubmitButton"
                            @click="submitCreateAsset"
                        >
                            Submit
                        </button>
                    </div>
        </div>
    </div>
</template>

<script>
import { reactive, computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

export default {
    name: 'CreateEntity',
    props: {
        group: String,
        closeModal: Function,
    },

    setup(props) {
        const createEntityRef = ref(null)
        const formRef = ref(null)
        const groupRef = ref(null)
        const store = useStore()
        const incomingName = computed(() => store.state.Entity?.incomingEntityName)
        const isCreateAssetCategory = computed(() => store?.state.Entity.showCreateAssetCategory)

        const state = reactive({
            disableSubmitButton: false,
            group: '',
            lifeSpan: '',
            category: '',
            showMore: false,
        })

        const toggleShowMore = () => {
            state.showMore = !state.showMore
        }

        watch(
            () => isCreateAssetCategory?.value,
            () => {
                document.body.style.overflow = isCreateAssetCategory.value ? 'hidden' : 'auto'
            }
        )

        const onNameChange = (value, stateName) => {
            state[stateName] = value.target.value
        }

        const closeCreateAssetCategoryModal = () => {
            props.closeModal()
          //  store.dispatch('Entity/toggleCreateEntity', false)
        }

        const submitCreateAsset = () => {
            state.disableSubmitButton = false

            if (groupRef.value?.value) {
                state.group = groupRef.value?.value
            }

            const { disableSubmitButton, showMore, ...data } = state

            store.dispatch('Transaction/createAssetCategory', data).then((resp) => {
                if (resp?.success) {
                    state.disableSubmitButton = false
                    state.group = ''
                    state.lifeSpan = ''
                    state.category = ''
                    state.showMore = false

                    formRef.value.reset()

                    // store.dispatch('Entity/toggleCreateEntity', false)
                    closeCreateAssetCategoryModal()
                } else {
                    state.disableSubmitButton = false
                    closeCreateAssetCategoryModal()

                }
            })
        }

        const handleOnClickOutsideModal = (e) => {
            if (createEntityRef?.value && !createEntityRef?.value.contains(e.target)) {
                closeCreateAssetCategoryModal()
            }
        }

        return {
            state,
            submitCreateAsset,
            isCreateAssetCategory,
            incomingName,
            onNameChange,
            groupRef,
            formRef,
            createEntityRef,
            handleOnClickOutsideModal,
            toggleShowMore,
        }
    },
}
</script>

<style scoped></style>
