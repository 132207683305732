<template>
    <CreateCategory
        :closeModal="() => onShowCreateCategory()"

     />
    <CreateBrand />
    
    <div
        v-if="isCreateProduct"
        tabindex="0"
        @keydown.esc="closeModal"
        class="w-100 center"
        id="add-product-form"
        style="z-index: 1"

        :class="isCreateProduct && 'jquery-modal blocker current'"
        @click="handleOnClickOutsideModal"
    >
        <div class="w-50-l w-90 pv2 center box-border" style="background: white" ref="createProductRef">
            <div class="flex justify-between pa3">
                    <p class="f3">Create New Asset</p>
                <div>
                    <router-link to="#">
                        <img src="../assets/images/Close-icon.svg" alt="" @click.prevent="closeModal" />
                    </router-link>
                </div>
            </div>

            <form class="entity-form" ref="formRef" @submit.prevent="onSubmit">
                <div class="pa3">
                    <!-- SKU /Barcode -->
                    <div class="flex flex-wrap justify-between pt2">
                        <div class="w-100 pr2-l" :class="state.showMore ? 'w-50-l' : 'w-100-l'">
                            <div class="pv2 font-w1">
                                <label for="sku">{{ transactionCategory === "SERVICE" ? "Service ID" :"SKU" }}<span class="required">*</span></label>
                            </div>
                            <input class="w-100" required type="text" id="sku" name="sku" placeholder="sku" v-model="state.sku" />
                        </div>
                        <div class="w-50-l w-100 pl2-l" v-if="state.showMore && transactionCategory !== 'SERVICE' ">
                            <div class="pv2 font-w1">
                                <label for="barcode"> Asset Category </label>
                            </div>
                            <input type="text" id="barcode" name="barcode" placeholder="0123ABC" v-model="state.barcode" />
                        </div>
                    </div>

                    <div class="flex flex-wrap justify-between">
                        <!-- Product name -->
                        <div class="pt2 w-100 w-50-l pr2-l">
                            <div class="pv2 font-w1"><label for="name">Asset Name</label></div>
                            <input
                                required
                                type="text"
                                id="name"
                                name="name"
                                placeholder="Product name"
                                @input="onInputProdName"
                                v-model="state.name"
                            />
                        </div>

                        <!-- Product name -->
                        <div class="pt2 w-100 w-50-l pl2-l">
                            <div class="pv2 font-w1"><label for="name">Quantity</label></div>
                            <input
                                required
                                type="text"
                                id="quantity"
                                name="quantity"
                                placeholder="Quantity"
                                v-model="state.quantity"
                            />
                        </div>
                    </div>
                     <!-- categories/brand -->
                    <div class="flex pt2">
                        <div class="w-50 pr2">
                            <div class="flex flex-wrap items-center" style="gap: 0.5rem">
                                <div class="pv2">
                                    <label for="category">Category</label>
                                </div>
                                <router-link @click.prevent="onShowCreateCategory(true)" to="#" class="pv2 active bold"
                                    >+ Add Category</router-link
                                >
                            </div>

                            <select name="categories" v-model="state.category" class="w-100" id="category">
                                <option value="" selected>Please choose a category</option>
                                <template v-for="category of state.assetCategories" v-bind:key="category._id">
                                    <option :value="category">{{ category.category }}</option></template
                                >
                            </select>
                        </div>
                        <div class="w-50 pl2">
                            <div class="flex flex-wrap items-center" style="gap: 0.5rem">
                                <div class="pv2"><label for="brands">Brand</label></div>
                                <router-link @click.prevent="onShowCreateBrand(true)" to="#" class="pv2 active bold">
                                    + Add New Brand</router-link
                                >
                            </div>

                            <select name="brands" v-model="state.brand" class="w-100" id="brands">
                                <option value="" selected>Please choose a brand</option>
                                <template v-for="brand of brands" v-bind:key="brand._id">
                                    <option :value="brand">{{ brand.name }}</option></template
                                >
                            </select>
                        </div>
                    </div>
                    <!--  Image area-->
                    <div
                        class="flex flex-wrap justify-between items-center pt3"
                        @drop.prevent="handleDragImage"
                        @dragover.prevent
                        @dragenter.prevent
                    >
                        <div class="w-20-l w-90 pb3 b f4 pb0-l box-border1">
                            <img
                                :src="state?.image || state?.imageUrl || require('@/assets/images/inventory-default.png')"
                                alt="Org Image"
                                class="h-90 w-90"
                                style="object-position: center; object-fit: cover"
                            />
                        </div>
                        <div class="box-border pa4 w-80-l w-90">
                            <span class="dn">
                                <input
                                    ref="imageElRef"
                                    type="file"
                                    name="imageFile"
                                    id="imageFile"
                                    accept="image/*"
                                    @change.prevent="handleSelectImage"
                                />
                            </span>
                            <span class="font-w1 lh-copy pointer" style="color: #132c8c" @click.prevent="handleClickUpload"
                                >Upload
                            </span>
                            or drag and drop your company logo PNG, JPG, GIF up to 10MB
                        </div>
                    </div>

                    <!-- textarea -->
                    <div class="pt2" v-if="state.showMore">
                        <div class="pv2 font-w1">
                            <label>Description</label>
                        </div>

                        <textarea
                            placeholder="Type here"
                            v-model="state.description"
                            name="description"
                            id="description"
                            cols="20"
                            rows="5"
                        ></textarea>
                    </div>
                   
                    <!-- size/color -->
                    <div  class="flex pt2" v-if="state.showMore && transactionCategory !== 'SERVICE'">
                        <div class="w-50 pr2">
                            <div class="pv2"><label for="size">Size</label></div>
                            <input type="text" v-model="state.size" placeholder="Product Size" id="size" name="size" />
                        </div>
                        <div class="w-50 pl2">
                            <div class="pv2"><label for="color">Color</label></div>
                            <input type="text" v-model="state.color" placeholder="Product Color" id="color" name="color" />
                        </div>
                    </div>
                    <!-- size/color -->
                    <div class="flex flex-wrap pt2 justify-between">
                        <div class="w-100 w-50-l pr2-l">
                            <div class="pv2"><label for="size">{{ transactionCategory !== 'SERVICE' ? 'Selling price' : 'Unit Price' }}</label></div>
                            <input
                                type="number"
                                v-model="state.sellingPrice"
                                step="any"
                                placeholder="e.g 10000"
                                id="sellingPrice"
                                name="sellingPrice"
                            />
                        </div>
                        <div v-if="transactionCategory !== 'SERVICE'" class="w-100 w-50-l pl2-l">
                            <div class="pv2"><label for="color">Buying price</label></div>
                            <input
                                type="number"
                                v-model="state.buyingPrice"
                                step="any"
                                placeholder="e.g 10000"
                                id="buyingPrice"
                                name="buyingPrice"
                            />
                        </div>
                    </div>
                    <div class="flex flex-wrap" style="gap: 1rem">
                        <button class="mt3" @click.prevent="toggleShowMore">
                            {{ state.showMore ? 'Hide more ' : 'Add more ' }}fields
                        </button>
                        <button class="mt3" type="submit" :disabled="state.disableSubmitButton">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <!--  New-->

    <!--    <div class="jquery-modal blocker current" style="position: fixed; top: 0; left: 0; z-index: 99999999999999">-->
    <!--        <div class="bg-white">-->
    <!--            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium enim fuga magnam molestiae molestias non-->
    <!--            perspiciatis repellat soluta sunt voluptatem.-->
    <!--        </div>-->
    <!--    </div>-->
</template>

<script>
import { computed, reactive, ref, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import ShortUniqueId from 'short-unique-id'

import CreateBrand from '@/components/CreateBrand'
import CreateCategory from '@/components/CreateAssetCategory'

export default {
    name: 'CreateProduct',
    props: {
        closeModal: Function,
        assetCategory: String
    },
    components: { CreateCategory, CreateBrand },

    setup(props) {
        const store = useStore()
        const createProductRef = ref(null)
        const imageElRef = ref(null)
        const productSku = computed(() => store?.state.Inventory.productSku)
        const isCreateProduct = computed(() => store?.state.Inventory.showCreateAssetProduct)
        const incomingProductName = computed(() => store?.state.Inventory.incomingProductName)
        const brands = computed(() => store?.state.Inventory.brands)
        // const categories = computed(() => store?.state.Inventory.categories)
        const category = computed(() => store?.state.Inventory.category)
        const brand = computed(() => store?.state.Inventory.brand)
        const createProductNotifier = computed(() => store.state.Inventory?.createProductNotifier)
        const productNameRef = ref(null)
        const formRef = ref(null)
        const brandRef = ref(null)
        const categories = computed(() => store.state?.Transaction?.assetsCategories)


        // onMounted(() => store.dispatch('Transaction/getAssetCategories'))
        //
        
        console.log(props.transactionCategory, 'category');

        const initialState = () => ({
            disableSubmitButton: false,
            name: '',
            sku: '',
            barcode: '',
            description: '',
            quantity: 0,
            category: '',
            categoryName: '',
            brand: '',
            brandName: '',
            size: '',
            color: '',
            measurementUnit: '',
            buyingPrice: 0,
            sellingPrice: 0,
            image: '',
            imageUrl: '',
            imageFile: '',
            showMore: false,
            type: props.transactionCategory,
            assetCategories: [],

        })

        const state = reactive(initialState())

        const toggleShowMore = () => {
            state.showMore = !state.showMore
        }

        watch(
            () => productSku.value,
            () => {
                state.sku = productSku.value
            }
        )

        watch(
            () => incomingProductName.value,
            () => {
                // eslint-disable-next-line no-underscore-dangle
                state.name = incomingProductName.value
                // console.log(state.category, 'new')
            }
        )

        watch(
            () => category.value,
            () => {
                state.category = category.value
                // console.log(state.category, 'new')
            }
        )

        watch(
            () => brand.value,
            () => {
                // eslint-disable-next-line no-underscore-dangle
                state.brand = brand.value
                // console.log(brand.value, 'new')
            }
        )

        watch(
            () => isCreateProduct.value,
            () => {
                document.body.style.overflow = isCreateProduct.value ? 'hidden' : 'auto'
            }
        )

        // change type
        watch(
            () => props.transactionCategory,
            () => {
                state.type = props.transactionCategory
            }
        )

         watch(
            () => categories.value,
            (newVal, oldVal) => {
                if (newVal !== oldVal) {
                    if (newVal) {
                        console.log('assetCategories', newVal?.assets)
                        const assets = newVal?.assets.filter(asset => asset.category)
                        state.assetCategories = assets
                    }
                }
            }
        )


        /* eslint-disable */
        const handleCloseModal = () => {
            // console.log('Esc key pressed.')
        }

        const onShowCreateBrand = (payload = true) => {
            store.dispatch('Inventory/showCreateBrand', payload)
        }
        const onShowCreateCategory = (payload = false) => {
           // store.dispatch('Inventory/showCreateCategory', payload)
            store.dispatch('Entity/toggleCreateAssetCAtegory', payload)

        }

        const onInputProdName = () => {
            store.dispatch('Inventory/showIncomingProductName', state.name)
        }

        const onChange = (event, stateName) => {
            if (stateName !== 'category' && stateName !== 'brand') {
                state[stateName] = event.target.value
            }
        }

        const closeCreateProductModal = () => {
            store.dispatch('Inventory/showCreateAssetProduct', {show: false, cat: props.transactionCategory})
        }

        const onSubmit = () => {
            if (state.quantity) {
                if (!state.buyingPrice && props.transactionCategory !== 'SERVICE') {
                    return store.dispatch('Alert/setAlert', { message: 'Buying price is required', status: false })
                }

                if (!state.sellingPrice) {
                    return store.dispatch('Alert/setAlert', { message: 'Selling price is required', status: false })
                }
                store.dispatch('Inventory/setTempInStockValue', state.quantity)
            }

            state.disableSubmitButton = true
            if (incomingProductName.value && !state.name) {
                state.name = productNameRef.value?.value || incomingProductName.value
            }

            //console.log(state, 'state')

            if (state.category) {
                state.categoryName = state.category?.name
                state.category = state.category?._id
            }

            if (state.brand) {
                state.brandName = state.brand?.name
                state.brand = state.brand?._id
            }

            // console.log(state, 'state')

            // if (!state.brand) {
            //     delete state.brand
            // } else {
            //     state.brand = state.brand?._id
            // }
            //
            // if (!state.category) {
            //     delete state.category
            // } else {
            //     state.category = state.category?._id
            // }

            if (state.buyingPrice) {
                parseFloat(state.buyingPrice)
            } else if(props.transactionCategory !== 'SERVICE') {
                return store.dispatch('Alert/setAlert', { message: 'Please enter your buying price', status: false })
            }

            if (state.sellingPrice) {
                parseFloat(state.sellingPrice)
            } else {
                return store.dispatch('Alert/setAlert', { message: 'Please enter your selling price', status: false })
            }

            const { quantity, disableSubmitButton, ...data } = state

            const formData = new FormData()

            formData.append('asset_category', state.category)
            formData.append('asset', true)

            // asset_category: cat,
            // asset: true,

            const dataKeys = Object.keys(data)

            dataKeys.forEach((dataKey) => {
                if (data[dataKey]) formData.append(dataKey, data[dataKey])
            })
            formData.delete('assetCategories')

            console.log(formData, 'formData');
            // formData.append('productCategory', props.transactionCategory)
            // console.log(dataKeys, state)

            // store.dispatch('Inventory/createProduct', data).then((resp) => {
            store.dispatch('Inventory/createProduct', formData).then((resp) => {
                if (quantity) {
                    if (state.buyingPrice && state.sellingPrice) {
                        const price = parseFloat(state.buyingPrice)
                        const qty = parseFloat(state.quantity)
                        const amount = price * qty
                        const name = state.name
                        const prodTx = {
                            type: 'purchases',
                            date: new Date(),
                            reference: new ShortUniqueId({ length: 6 }).randomUUID(),
                            products: [{ name, product: createProductNotifier?.value?._id, price, quantity: qty }],
                            amount: parseFloat(amount),
                        }

                        store.dispatch('Transaction/createNewTransaction', prodTx)
                    } else {
                        const name = state.name
                        const prodTx = {
                            type: 'purchases',
                            date: new Date(),
                            reference: new ShortUniqueId({ length: 6 }).randomUUID(),
                            services: [{ name, service: createProductNotifier?.value?._id, price: state.sellingPrice, }],
                            unit_amount: parseFloat(state.sellingPrice),
                        }
                        store.dispatch('Transaction/createNewTransaction', prodTx)

                    }

                }

                if (resp.success) {
                    Object.assign(state, initialState())
                    formRef.value.reset()

                    // store.dispatch('Inventory/showCreateAssetProduct', false)
                    closeCreateProductModal()
                } else {
                    state.disableSubmitButton = false
                }
            })
        }

        const handleOnClickOutsideModal = (e) => {
            if (createProductRef.value && !createProductRef.value.contains(e.target)) {
                closeCreateProductModal()
            }
        }

        const handleClickUpload = () => {
            // eslint-disable-next-line
            imageElRef?.value?.click()
            // imageElRef?.value.click('click')
        }

        const handleSelectImage = (e) => {
            if (e?.target?.files?.length) {
                const file = e.target.files[0]
                // eslint-disable-next-line prefer-destructuring
                state.imageFile = e.target.files[0]
                const reader = new FileReader()
                reader.onload = (e) => {
                    state.imageFile = file
                    state.imageUrl = e.target.result

                    if (reader.readyState === 2) {
                        state.image = reader.result.toString()
                    }
                }
                reader.readAsDataURL(file)
            }
        }

        const handleDragImage = (e) => {
            if (e?.dataTransfer?.files?.length) {
                const file = e.dataTransfer.files[0]
                // eslint-disable-next-line prefer-destructuring
                state.imageFile = e.dataTransfer.files[0]
                const reader = new FileReader()
                reader.onload = (e) => {
                    state.imageFile = file
                    state.imageUrl = e.target.result

                    if (reader.readyState === 2) {
                        state.image = reader.result.toString()
                    }
                }
                reader.readAsDataURL(file)
            }
        }

        return {
            state,
            onShowCreateBrand,
            onShowCreateCategory,
            isCreateProduct,
            productNameRef,
            formRef,
            incomingProductName,
            brands,
            categories,
            onChange,
            onInputProdName,
            onSubmit,
            brandRef,
            handleCloseModal,
            handleOnClickOutsideModal,
            createProductRef,
            handleClickUpload,
            handleDragImage,
            handleSelectImage,
            imageElRef,
            toggleShowMore,
        }
    },
}
</script>

<style scoped></style>
